import { Controller } from '@hotwired/stimulus';
import { debounce } from 'lodash-es';

// Connects to data-controller="otp-submit"
export default class extends Controller {
  static targets = ['input'];
  isSubmitting = false;

  connect() {
    this.inputTarget.addEventListener(
      'keyup',
      debounce(this.submit.bind(this), 300)
    );

    this.inputTarget.addEventListener(
      'keydown',
      this.handleEnterKey.bind(this)
    );
  }

  handleEnterKey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.submit();
    }
  }

  submit() {
    const input = this.inputTarget.value;

    if (input.length === 6 && !this.isSubmitting) {
      this.isSubmitting = true;
      this.element.submit();
    }
  }
}
